import './style.scss';

import { IconButton, Tooltip } from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import first500UserThatPayed from 'assets/img/badge.png';
import { Avatar } from 'components/avatar/avatar';
import { IconRoundButton } from 'components/iconRoundButton';
import { Popover } from 'components/popover/popover';
import { SettingsPopover } from 'components/settingsPopover';
import { SubscriptionIcon } from 'components/subscriptionIcon';
import { USER_ADMIN, USER_CREATOR, USER_MANAGER } from 'constants/index';
import ExitButton from 'containers/exitButton/exitButton';
import * as AvatarCreators from 'creators/avatar';
// eslint-disable-next-line
import React, { MouseEvent, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { is_first_500$, subscription$, userName$, userType$ } from 'selectors/auth';
import { avatar$ } from 'selectors/avatar';

import { CreatePopoverContent } from './createPopoverContent';
import style from './infoPanel.module.scss';

interface IStateProps {}
interface OwnProps {
  isShowPopupsButtons?: boolean;
}
interface IDispatchProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const InfoPanel = ({ isShowPopupsButtons }: IProps) => {
  const isFirst500 = useSelector(is_first_500$);
  const avatar = useSelector(avatar$);
  const userName = useSelector(userName$);
  const userType = useSelector(userType$);
  const subscription = useSelector(subscription$);
  const dispatch = useDispatch();

  const [anchorElCreating, setAnchorElCreating] = useState<HTMLButtonElement | null>(null);
  const openCreating = Boolean(anchorElCreating);
  const idCreating = openCreating ? 'create-popover' : undefined;
  const handleClickCreating = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElCreating(event.currentTarget);
  };

  const handleCloseCreating = () => setAnchorElCreating(null);

  useEffect(() => {
    if (userName) {
      dispatch(AvatarCreators.getAvatar());
    }
    // eslint-disable-next-line
  }, [userName]);

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const openGear = Boolean(anchor);
  const gearId = openGear ? 'simple-popover' : undefined;
  const handleGearClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleGearClose = () => setAnchor(null);

  return (
    <section className="infopanel d-flex align-center">
      <div className="infopanel-wrap d-flex align-center">
        {isShowPopupsButtons && (
          <div className="infopanel-popups">
            <IconRoundButton
              icon={<AddBoxRoundedIcon color="action" className="action-add-btn" />}
              tooltipText="Create"
              className="avatar_icon"
              onClick={handleClickCreating}
              style={{
                padding: 5,
              }}
            />

            <Popover
              id={idCreating}
              open={openCreating}
              anchorEl={anchorElCreating}
              onClose={handleCloseCreating}
              verticalAnchor="bottom"
              horizontalAnchor="left"
              verticalTransform="top"
              horizontalTransform="left"
              content={<CreatePopoverContent isSHAdmin={userType === 'admin'} onClosePopover={handleCloseCreating} />}
            />
          </div>
        )}
        <span className="avatar-text">{userName ? userName : 'User name'}</span>
        {userType === USER_CREATOR && <SubscriptionIcon subscription={subscription} />}
        {isFirst500 && (
          <Tooltip title={'SmashHaus First 500 Badge'}>
            <img src={first500UserThatPayed} className={style.first500Badge} alt="First 500 users" />
          </Tooltip>
        )}
        <Avatar src={avatar} />

        <>
          <IconButton className={style.settingsBtn} size="small" aria-describedby={gearId} onClick={handleGearClick}>
            <SettingsRoundedIcon className={style.settings} />
          </IconButton>

          <Popover
            id={gearId}
            open={openGear}
            anchorEl={anchor}
            onClose={handleGearClose}
            verticalAnchor="bottom"
            horizontalAnchor="left"
            verticalTransform="top"
            horizontalTransform="left"
            content={<SettingsPopover isManager={userType === USER_MANAGER || userType === USER_ADMIN} onClosePopover={handleGearClose} />}
          />
        </>

        <ExitButton />
      </div>
    </section>
  );
};

export default memo(InfoPanel);

import Swal from 'sweetalert2';

export const errorNotification = (message: string, timer: number = 4000) => {
  return Swal.fire({
    timerProgressBar: true,
    timer,
    grow: 'column',
    toast: true,
    position: 'bottom-end',
    icon: 'error',
    title: message,
    customClass: {
      container: 'zindex100000',
    },
    showClass: {
      popup: 'animated fadeInDown faster zindex1000',
    },
    hideClass: {
      popup: 'animated fadeOutUp faster',
    },
  });
};

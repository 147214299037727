import { Action } from 'redux';

import * as Const from '../constants';

export const setAuthInit = (): Action => {
  return {
    type: Const.AUTH_INIT,
  };
};

export const getSuccessAuth = (): Action => {
  return {
    type: Const.AUTH_SUCCESS,
  };
};

export const setAuthFail = (): Action => {
  return {
    type: Const.AUTH_FAIL,
  };
};

export const setAuthLoading = (): Action => {
  return {
    type: Const.AUTH_LOADING,
  };
};

export const getUserType = (payload: 'creator' | 'admin' | 'manager') => {
  return {
    type: Const.AUTH_USER_TYPE,
    payload,
  };
};

export const getUserEmail = (payload: string) => {
  return {
    type: Const.AUTH_EMAIL,
    payload,
  };
};

export const getCompanyAvatar = (payload: string | null) => {
  return {
    type: Const.AUTH_COMPANY_AVATAR,
    payload,
  };
};

export const getUserName = (payload: string) => {
  return {
    type: Const.AUTH_USER_NAME,
    payload,
  };
};

export const getUserSubscription = (payload: string) => {
  return {
    type: Const.AUTH_SUBSCRIPTION,
    payload,
  };
};

export const authExit = (): Action => {
  return {
    type: Const.AUTH_EXIT,
  };
};

export const initAllApplication = (): Action => {
  return {
    type: Const.INIT_ALL_APP,
  };
};

export const setAffiliate = (payload: boolean) => {
  return {
    type: Const.AUTH_IS_AFFILIATE,
    payload,
  };
};

export const setCheckedEmail = (payload: boolean | null) => {
  return {
    type: Const.AUTH_CHECK_EMAIL,
    payload,
  };
};

export const setLoginBg = (payload: string | null) => {
  return {
    type: Const.AUTH_LOGIN_BG,
    payload,
  };
};

export const setCompanyId = (payload: number | null) => {
  return {
    type: Const.AUTH_COMPANY_ID,
    payload,
  };
};

export const setIs500UserPayed = (payload: boolean) => {
  return {
    type: Const.AUTH_IS_FIRST_PAY_USER,
    payload,
  };
};

export const setSID = (payload: string | null) => {
  return {
    type: Const.AUTH_SID,
    payload,
  };
};

export const setCouponPopup = (payload: boolean) => {
  return {
    type: Const.AUTH_OPEN_COUPON_POPUP,
    payload,
  };
};

export const openTechnicalPage = (payload: boolean) => {
  return {
    type: Const.AUTH_TECHNICAL_PAGE,
    payload,
  };
};

export const setErrorText = (payload: string | null) => {
  return {
    type: Const.AUTH_ERROR_TEXT,
    payload,
  };
};

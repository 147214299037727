import { Loader } from 'components/loader/loader';
import { LogoBottom } from 'components/logoBottom/logoBottom';
import ServiceWorkerWrapper from 'components/serviceWorkerWrapper';
import { DeviceHandler } from 'containers/deviceHandler';
import { HistoryHandler } from 'containers/historyHandler';
import createBrowserHistory from 'history/createBrowserHistory';
// eslint-disable-next-line
import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import styles from './App.module.scss';
import State from './interfaces/state/State';
import { isLoading$ } from './selectors/auth';

export const history = createBrowserHistory();
interface IStateProps {
  isLoading: boolean;
}

interface IDispatchProps {}
interface OwnProps {}
interface IProps extends IStateProps, IDispatchProps, OwnProps {}

const App = (props: IProps) => {
  const { isLoading } = props;

  const renderLoading = useMemo(() => {
    return (
      isLoading && (
        <div className={styles.container}>
          <Loader />
          <div className={styles.loader}>
            <LogoBottom loader />
          </div>
        </div>
      )
    );
  }, [isLoading]);

  const renderApp = useMemo(() => {
    return (
      <>
        <ServiceWorkerWrapper />
        <Router>
          <HistoryHandler />
          <DeviceHandler />
        </Router>
      </>
    );
  }, []);

  return (
    <div className={styles.root}>
      {renderApp}
      {renderLoading}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isLoading: isLoading$(state),
  };
};

export default connect(mapStateToProps)(
  memo(App, (_prevProps: IProps, _props: IProps) => {
    return false;
  })
);

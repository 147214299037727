import { getCompanyId, getLoginBg } from 'interceptors';
import { SubscriptionName } from 'interfaces/state/SubscriptionList';

import * as Const from '../constants';
import State from '../interfaces/state/AuthState';

const initState: State = {
  isLoading: false,
  isError: false,
  isAuth: false,
  userType: '',
  userName: '',
  userEmail: '',
  subscription: 'free' as SubscriptionName,
  isAffiliate: false,
  isCheckedEmail: null,
  companyAvatar: null,
  loginBg: getLoginBg(),
  companyId: getCompanyId() ? Number(getCompanyId()) : null,
  is_first_500: false,
  sid: null,
  isOpenCouponPopup: false,
  isOpenTechnicalPage: false,
  errorText: null,
};

export default function auth(state: State = initState, action: any): State {
  switch (action.type) {
    case Const.AUTH_INIT:
    case Const.INIT_ALL_APP:
      return {
        ...initState,
      };

    case Const.AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isAuth: true,
      };

    case Const.AUTH_FAIL:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isAuth: false,
      };

    case Const.AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case Const.AUTH_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };

    case Const.AUTH_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };

    case Const.AUTH_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };

    case Const.AUTH_EXIT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isAuth: false,
        userType: 'creator',
        userName: '',
        isAffiliate: false,
      };

    case Const.AUTH_IS_AFFILIATE:
      return {
        ...state,
        isAffiliate: action.payload,
      };

    case Const.AUTH_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };

    case Const.AUTH_COMPANY_AVATAR:
      return {
        ...state,
        companyAvatar: action.payload,
      };

    case Const.AUTH_CHECK_EMAIL:
      return {
        ...state,
        isCheckedEmail: action.payload,
        isLoading: false,
      };

    case Const.AUTH_LOGIN_BG:
      return {
        ...state,
        loginBg: action.payload,
      };

    case Const.AUTH_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };

    case Const.AUTH_IS_FIRST_PAY_USER:
      return {
        ...state,
        is_first_500: action.payload,
      };

    case Const.AUTH_SID:
      return {
        ...state,
        sid: action.payload,
      };

    case Const.AUTH_OPEN_COUPON_POPUP:
      return {
        ...state,
        isOpenCouponPopup: action.payload,
      };

    case Const.AUTH_TECHNICAL_PAGE:
      return {
        ...state,
        isOpenTechnicalPage: action.payload,
      };

    case Const.AUTH_ERROR_TEXT:
      return {
        ...state,
        errorText: action.payload,
      };

    default:
      return state;
  }
}

export const INIT_ALL_APP = 'INIT_ALL_APP';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_USER_TYPE = 'AUTH_USER_TYPE';
export const AUTH_USER_NAME = 'AUTH_USER_NAME';
export const AUTH_SUBSCRIPTION = 'AUTH_SUBSCRIPTION';
export const AUTH_EXIT = 'AUTH_EXIT';
export const AUTH_EMAIL = 'AUTH_EMAIL';
export const AUTH_IS_AFFILIATE = 'AUTH_IS_AFFILIATE';
export const AUTH_CHECK_EMAIL = 'AUTH_CHECK_EMAIL';
export const AUTH_COMPANY_AVATAR = 'AUTH_COMPANY_AVATAR';
export const AUTH_LOGIN_BG = 'AUTH_LOGIN_BG';
export const AUTH_COMPANY_ID = 'AUTH_COMPANY_ID';
export const AUTH_IS_FIRST_PAY_USER = 'AUTH_IS_FIRST_PAY_USER';
export const AUTH_SID = 'AUTH_SID';
export const AUTH_OPEN_COUPON_POPUP = 'AUTH_OPEN_COUPON_POPUP';
export const AUTH_TECHNICAL_PAGE = 'AUTH_TECHNICAL_PAGE';
export const AUTH_ERROR_TEXT = 'AUTH_ERROR_TEXT';

export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const PASS_INIT = 'PASS_INIT';
export const PASS_SUCCESS = 'PASS_SUCCESS';
export const PASS_TYPE = 'PASS_TYPE';
export const PASS_SECOND_SUCCESS = 'PASS_SECOND_SUCCESS';

export const TAB_INIT = 'TAB_INIT';
export const TAB_RECEIVE = 'TAB_RECEIVE';

export const EMAIL_PLACEHOLDER = 'Email';
export const PASS_PLACEHOLDER = 'Password';

export const USER_MANAGER = 'manager';
export const USER_CREATOR = 'creator';
export const USER_COMPANY = 'company';
export const USER_ADMIN = 'admin';
export const USER_MUSICIAN = 'musician';

export const SIGNUP_INIT = 'SIGNUP_INIT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const SIGNUP_USER_NAME = 'SIGNUP_USER_NAME';
export const SIGNUP_PENDING = 'SIGNUP_PENDING';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_SUCCESS_RED_TO_LOG_IN = 'SIGNUP_SUCCESS_RED_TO_LOG_IN';
export const SIGNUP_REDIRECT_DEFAULT = 'SIGNUP_REDIRECT_DEFAULT';

export const COMPANY_JOBS_INIT = 'COMPANY_JOBS_INIT';
export const COMPANY_JOBS_SUCCESS = 'COMPANY_JOBS_SUCCESS';
export const COMPANY_JOBS_PENDING = 'COMPANY_JOBS_PENDING';
export const COMPANY_JOBS_ERROR = 'COMPANY_JOBS_ERROR';
export const COMPANY_JOBS_SORTING = 'COMPANY_JOBS_SORTING';
export const COMPANY_JOBS_ORDER = 'COMPANY_JOBS_ORDER';
export const COMPANY_SORT_TYPE = 'COMPANY_SORT_TYPE';
export const COMPANY_SUCCESS_ADDED = 'COMPANY_SUCCESS_ADDED';
export const COMPANY_CHANGE_STATUS = 'COMPANY_CHANGE_STATUS';
export const COMPANY_NEW_ID = 'COMPANY_NEW_ID';
export const COMPANY_UPDATE_JOB = 'COMPANY_UPDATE_JOB';
export const COMPANY_UPDATE_JOB_COMPANY_NAME = 'COMPANY_UPDATE_JOB_COMPANY_NAME';
export const COMPANY_UPDATE_JOB_AVATAR = 'COMPANY_UPDATE_JOB_AVATAR';
export const COMPANY_UPDATE_JOB_COMPANY = 'COMPANY_UPDATE_JOB_COMPANY';
export const COMPANY_JOB_SET_PEND_OFF = 'COMPANY_JOB_SET_PEND_OFF';
export const COMPANY_UPDATE_JOB_AVATAR_BY_ID = 'COMPANY_UPDATE_JOB_AVATAR_BY_ID';
export const COMPANY_JOB_SELECT_TYPE = 'COMPANY_JOB_SELECT_TYPE';
export const COMPANY_JOB_INIT_FROM_FETCH = 'COMPANY_JOB_INIT_FROM_FETCH';

export const NEW_TRACKS_INIT = 'NEW_TRACKS_INIT';
export const NEW_TRACKS_Q_FILTER_INIT = 'NEW_TRACKS_Q_FILTER_INIT';
export const NEW_TRACKS_SUCCESS = 'NEW_TRACKS_SUCCESS';
export const NEW_TRACKS_SUCCESS_WITH_LIMIT = 'NEW_TRACKS_SUCCESS_WITH_LIMIT';
export const NEW_TRACKS_PENDING = 'NEW_TRACKS_PENDING';
export const NEW_TRACKS_ERROR = 'NEW_TRACKS_ERROR';
export const NEW_TRACKS_MUSIC_ADD_LIST = 'NEW_TRACKS_MUSIC_ADD_LIST';
export const NEW_TRACKS_ALL_ADD_LIST = 'NEW_TRACKS_ALL_ADD_LIST';
export const NEW_TRACKS_AMOUNT = 'NEW_TRACKS_AMOUNT';
export const NEW_TRACKS_SUBMITTED_SONGS = 'NEW_TRACKS_SUBMITTED_SONGS';
export const NEW_TRACKS_ALL_ADD = 'NEW_TRACKS_ALL_ADD';
export const EDIT_AND_SAVE_NEW_ITEM = 'EDIT_AND_SAVE_NEW_ITEM';
export const DELETE_TRACK_FROM_USER_TRACKLIST = 'DELETE_TRACK_FROM_USER_TRACKLIST';
export const TRACKS_CHANGE_ITEM = 'TRACKS_CHANGE_ITEM';
export const CURRENT_TRACK_INFO = 'CURRENT_TRACK_INFO';
export const TRACK_CURRENT_ID = 'TRACK_CURRENT_ID';
export const CURRENT_SORT_TYPE = 'CURRENT_SORT_TYPE';
export const CURRENT_ORDER_BY = 'CURRENT_ORDER_BY';
export const FETCH_TRACK_ID = 'FETCH_TRACK_ID';
export const DEL_SELECT_TRACKS = 'DEL_SELECT_TRACKS';
export const SELECT_TRACKS = 'SELECT_TRACKS';
export const SELECT_TRACKS_IDS = 'SELECT_TRACKS_IDS';
export const DELETE_TRACK_FROM_MUSICIAN = 'DELETE_TRACK_FROM_MUSICIAN';
export const NEW_TRACKS_FOR_PLAYLIST = 'NEW_TRACKS_FOR_PLAYLIST';
export const ADD_TRACK_FOR_PLAYLIST = 'ADD_TRACK_FOR_PLAYLIST';
export const DEL_TRACK_FROM_PLAYLIST = 'DEL_TRACK_FROM_PLAYLIST';
export const DEL_TRACK_FROM_PLAYLIST_CREATOR = 'DEL_TRACK_FROM_PLAYLIST_CREATOR';
export const ADD_TRACK_TO_PLAYLIST = 'ADD_TRACK_TO_PLAYLIST';
export const APPLICATION_SAVE_CHANGES = 'APPLICATION_SAVE_CHANGES';
export const APP_HAS_UNSAVED = 'APP_HAS_UNSAVED';
export const DELETE_ALL_TRACKS_FROM_PLAYLIST = 'DELETE_ALL_TRACKS_FROM_PLAYLIST';
export const CHANGE_CURRENT_INDEX_RATING = 'CHANGE_CURRENT_INDEX_RATING';
export const NEW_TRACKS_CURR_NAME = 'NEW_TRACKS_CURR_NAME';
export const NEW_TRACKS_CURR_DESC = 'NEW_TRACKS_CURR_DESC';
export const NEW_TRACKS_CURR_TAGS = 'NEW_TRACKS_CURR_TAGS';
export const NEW_TRACKS_LOADED = 'NEW_TRACKS_LOADED';
export const DEFAULT_SELECTED_TRACKS = 'DEFAULT_SELECTED_TRACKS';
export const DELETE_TRACKS_FROM_ALL_TRACKS = 'DELETE_TRACKS_FROM_ALL_TRACKS';
export const EDIT_TRACK_FROM_MUSICIAN = 'EDIT_TRACK_FROM_MUSICIAN';
export const SET_NEW_TRACK_FILTER_BY = 'SET_NEW_TRACK_FILTER_BY';
export const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';
export const SET_CURRENT_PLAYLIST_ID_FOR_SHARED = 'SET_CURRENT_PLAYLIST_ID_FOR_SHARED';
export const SHARE_TYPE = 'SHARE_TYPE';
export const CHANGE_PLAYLIST_TRACK_RATING = 'CHANGE_PLAYLIST_TRACK_RATING';
export const NEW_TRACKS_APPEARED = 'NEW_TRACKS_APPEARED';
export const EDIT_TRACK = 'EDIT_TRACK';
export const CREATOR_PLAYLIST_TRACKS = 'CREATOR_PLAYLIST_TRACKS';
export const CREATOR_SET_SELECTED_TRACKS = 'CREATOR_SET_SELECTED_TRACKS';

export const HELP_INIT = 'HELP_INIT';
export const HELP_SUCCESS = 'HELP_SUCCESS';
export const HELP_PENDING = 'HELP_PENDING';
export const HELP_ERROR = 'HELP_ERROR';
export const HELP_FIELD_VALUE = 'HELP_FIELD_VALUE';

export const MESSAGES_INIT = 'MESSAGES_INIT';
export const MESSAGES_PENDING = 'MESSAGES_PENDING';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGE_IS_READED = 'MESSAGE_IS_READED';

export const MUSICIAN_JOBS_INIT = 'MUSICIAN_JOBS_INIT';
export const MUSICIAN_JOBS_SUCCESS = 'MUSICIAN_JOBS_SUCCESS';
export const ALL_MUSICIAN_JOBS_SUCCESS = 'ALL_MUSICIAN_JOBS_SUCCESS';
export const MUSICIAN_JOBS_PAGINATION = 'MUSICIAN_JOBS_PAGINATION';
export const MUSICIAN_JOBS_PENDING = 'MUSICIAN_JOBS_PENDING';
export const MUSICIAN_JOBS_ERROR = 'MUSICIAN_JOBS_ERROR';
export const MUSICIAN_JOBS_SORTING = 'MUSICIAN_JOBS_SORTING';
export const MUSICIAN_JOBS_SORT_TYPE = 'MUSICIAN_JOBS_SORT_TYPE';
export const MUSICIAN_ALL_JOBS_SUCCESS = 'MUSICIAN_ALL_JOBS_SUCCESS';
export const CURR_JOB_ID = 'CURR_JOB_ID';
export const MUSICIAN_JOBS_LOADED = 'MUSICIAN_JOBS_LOADED';
export const MUSICIAN_CURR_JOB_INDEX = 'MUSICIAN_CURR_JOB_INDEX';
export const MUSICIAN_ALL_JOBS_SET_AVATAR_BY_ID = 'MUSICIAN_ALL_JOBS_SET_AVATAR_BY_ID';
export const MUSICIAN_JOBS_SET_AVATAR_BY_ID = 'MUSICIAN_JOBS_SET_AVATAR_BY_ID';
export const MUSICIAN_CHANGE_STATUS = 'MUSICIAN_CHANGE_STATUS';
export const MUSICIAN_JOB_CHANGE_SHARE_TYPE = 'MUSICIAN_JOB_CHANGE_SHARE_TYPE';
export const MUSICIAN_ADD_NEW_JOB_TO_ALL_JOBS = 'MUSICIAN_ADD_NEW_JOB_TO_ALL_JOBS';
export const MUSICIAN_CHANGE_ALLOW_TRACKS_NUM = 'MUSICIAN_CHANGE_ALLOW_TRACKS_NUM';
export const CHANGE_ONE_JOB_INFO = 'CHANGE_ONE_JOB_INFO';
export const SET_CURRENT_MUSICIAN_JOB = 'SET_CURRENT_MUSICIAN_JOB';
export const JOB_SEARCH = 'JOB_SEARCH';
export const JOB_TYPES = 'JOB_TYPES';
export const JOB_STATUS = 'JOB_STATUS';
export const JOB_TEAM_TYPE = 'JOB_TEAM_TYPE';
export const JOB_COUNTER = 'JOB_COUNTER';
export const JOB_TEAM_COUNTER = 'JOB_TEAM_COUNTER';

export const UPLOAD_TRACK_INIT = 'UPLOAD_TRACK_INIT';
export const UPLOAD_TRACK_SUCCESS = 'UPLOAD_TRACK_SUCCESS';
export const UPLOAD_TRACK_ERROR = 'UPLOAD_TRACK_ERROR';
export const UPLOAD_TRACK_PENDING = 'UPLOAD_TRACK_PENDING';
export const UPLOAD_TRACK_RECEIVE = 'UPLOAD_TRACK_RECEIVE';
export const SET_PROGRESS = 'SET_PROGRESS';
export const UPLOAD_TRACK_POPUP = 'UPLOAD_TRACK_POPUP';
export const UPLOAD_DURATION_TRACK = 'UPLOAD_DURATION_TRACK';
export const UPLOAD_FILE_SIZE_TRACK = 'UPLOAD_FILE_SIZE_TRACK';

export const APP_INIT = 'APP_INIT';
export const APP_SUCCESS = 'APP_SUCCESS';
export const APP_ERROR = 'APP_ERROR';
export const APP_PENDING = 'APP_PENDING';
export const APP_ADD_ONE_SUCCESS = 'APP_ADD_ONE_SUCCESS';
export const SET_PLAYLIST_ID_APP = 'SET_PLAYLIST_ID_APP';
export const DELETE_PLAYLIST_APP = 'DELETE_PLAYLIST_APP';
export const SET_CURRENT_PLAYLIST_APP = 'SET_CURRENT_PLAYLIST_APP';
export const SET_CURRENT_INIT_PLAYLIST_APP = 'SET_CURRENT_INIT_PLAYLIST_APP';
export const UPDATE_PLAYLIST_APP = 'UPDATE_PLAYLIST_APP';
export const SHAREABLE_LINKS_APP = 'SHAREABLE_LINKS_APP';
export const SHAREABLE_LINKS_ADD_ONE = 'SHAREABLE_LINKS_ADD_ONE';

export const PLAYLIST_INIT = 'PLAYLIST_INIT';
export const PLAYLIST_SUCCESS = 'PLAYLIST_SUCCESS';
export const PLAYLIST_ERROR = 'PLAYLIST_ERROR';
export const PLAYLIST_PENDING = 'PLAYLIST_PENDING';
export const PLAYLIST_RECEIVE = 'PLAYLIST_RECEIVE';
export const CURR_PLAYLIST_NAME = 'CURR_PLAYLIST_NAME';
export const SET_PLAYLIST_ID = 'SET_PLAYLIST_ID';
export const PLAYLIST_LOADED = 'PLAYLIST_LOADED';
export const PLAY_FROM_TRACK_PANEL = 'PLAY_FROM_TRACK_PANEL';
export const PLAYLIST_NAME_IN_BASE = 'PLAYLIST_NAME_IN_BASE';
export const PLAYLIST_TITLE_IN_BASE = 'PLAYLIST_TITLE_IN_BASE';
export const PLAYLIST_WRITER_IN_BASE = 'PLAYLIST_WRITER_IN_BASE';
export const CURRENT_PLAYLIST_NUM = 'CURRENT_PLAYLIST_NUM';
export const INIT_PLAYLIST = 'INIT_PLAYLIST';
export const DEL_PLAYLIST = 'DEL_PLAYLIST';
export const PLAYLIST_AUTOFORWARDING = 'PLAYLIST_AUTOFORWARDING';
export const SHARED_PLAYLIST_LIST = 'SHARED_PLAYLIST_LIST';
export const PLAYLIST_ADD_NEW_ITEM = 'PLAYLIST_ADD_NEW_ITEM';
export const SHARED_ID = 'SHARED_ID';
export const SHARED_INDEX = 'SHARED_INDEX';
export const UPD_ADDED_TRACKS_NUMBER = 'UPD_ADDED_TRACKS_NUMBER';
export const SET_DEFAULT_PLAYLIST_TRACKS = 'SET_DEFAULT_PLAYLIST_TRACKS';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const SET_APP_FOR_CREATOR_PLAYLIST = 'SET_APP_FOR_CREATOR_PLAYLIST';
export const DEL_APP_FROM_CREATOR_PLAYLIST = 'DEL_APP_FROM_CREATOR_PLAYLIST';
export const ADD_APP_TO_CREATOR_PLAYLIST = 'ADD_APP_TO_CREATOR_PLAYLIST';
export const PLAYLIST_AMOUNT = 'PLAYLIST_AMOUNT';
export const PLAYLIST_LOADING_TRACKS = 'PLAYLIST_LOADING_TRACKS';

export const CONFIRM_INIT = 'CONFIRM_INIT';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'CONFIRM_ERROR';
export const CONFIRM_PENDING = 'CONFIRM_PENDING';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_TOKEN = 'CONFIRM_TOKEN';

export const APP_CR_INIT = 'APP_CR_INIT';
export const APP_CR_SUCCESS = 'APP_CR_SUCCESS';
export const APP_CR_ERROR = 'APP_CR_ERROR';
export const APP_CR_PENDING = 'APP_CR_PENDING';
export const FILTER_APP_TRACK_ID = 'FILTER_APP_TRACK_ID';
export const ADD_APP_TRACK_ID = 'ADD_APP_TRACK_ID';
export const APP_JOB_ID = 'APP_JOB_ID';
export const APP_DESCRIPTION = 'APP_DESCRIPTION';
export const APP_INIT_CREATE = 'APP_INIT_CREATE';

export const STATS_INIT = 'STATS_INIT';
export const STATS_SUCCESS = 'STATS_SUCCESS';
export const STATS_PENDING = 'STATS_PENDING';
export const STATS_ERROR = 'STATS_ERROR';

export const USERS_INIT = 'USERS_INIT';
export const USERS_TYPE_RECEIVE = 'USERS_TYPE_RECEIVE';
export const USERS_RECEIVE = 'USERS_RECEIVE';
export const USERS_RECEIVE_FAIL = 'USERS_RECEIVE_FAIL';
export const USERS_RECEIVE_PENDING = 'USERS_RECEIVE_PENDING';
export const USERS_CURR_USER = 'USERS_CURR_USER';

export const RATING_INIT = 'RATING_INIT';
export const RATING_SUCCESS = 'RATING_SUCCESS';
export const RATING_RECEIVE = 'RATING_RECEIVE';
export const RATING_ERROR = 'RATING_ERROR';
export const RATING_PENDING = 'RATING_PENDING';
export const RATING_TRACK_RECEIVE_SUCCESS = 'RATING_TRACK_RECEIVE_SUCCESS';
export const RATING_FR_TO = 'RATING_FR_TO';
export const RATING_PULLED = 'RATING_PULLED';

export const SONG_INIT = 'SONG_INIT';
export const CURR_SONG = 'CURR_SONG';
export const CURR_SONG_TIME = 'CURR_SONG_TIME';
export const SET_SONG_PLAY = 'SET_SONG_PLAY';
export const SET_SONG_PAUSE = 'SET_SONG_PAUSE';
export const SET_SONG_RATING = 'SET_SONG_RATING';
export const SET_SONG_VOLUME = 'SET_SONG_VOLUME';
export const SET_SONG_NAME = 'SET_SONG_NAME';
export const SET_SONG_ARTIST = 'SET_SONG_ARTIST';
export const SET_SONG_DURATION = 'SET_SONG_DURATION';
export const SET_SONG_CLICK_TIME = 'SET_SONG_CLICK_TIME';
export const CURR_SONG_INDEX = 'CURR_SONG_INDEX';
export const SET_PLAY_AFTER_SRC_CHANGED = 'SET_PLAY_AFTER_SRC_CHANGED';
export const SET_MUTED_ON_PLAYER = 'SET_MUTED_ON_PLAYER';
export const SCROLL_TO_TRACK = 'SCROLL_TO_TRACK';

export const SHARE_INIT = 'SHARE_INIT';
export const SHARE_SUCCESS = 'SHARE_SUCCESS';
export const SHARE_PENDING = 'SHARE_PENDING';
export const SHARE_ERROR = 'SHARE_ERROR';

export const DOWNL_INIT = 'DOWNL_INIT';
export const DOWNL_SUCCESS = 'DOWNL_SUCCESS';
export const DOWNL_ERROR = 'DOWNL_ERROR';
export const DOWNL_PENDING = 'DOWNL_PENDING';

export const RECEIVE_PASS_INIT = 'RECEIVE_PASS_INIT';
export const RECEIVE_PASS_SUCCESS = 'RECEIVE_PASS_SUCCESS';
export const RECEIVE_PASS_PENDING = 'RECEIVE_PASS_PENDING';
export const RECEIVE_PASS_ERROR = 'RECEIVE_PASS_ERROR';
export const RECEIVE_PASS_TOKEN = 'RECEIVE_PASS_TOKEN';
export const RECEIVE_PASS_EMAIL = 'RECEIVE_PASS_EMAIL';
export const SEND_RECEIVE_PASS_SUCCESS = 'SEND_RECEIVE_PASS_SUCCESS';

export const MAX_LOGIN_LENGTH = 100;
export const MAX_PASSWORD_LENGTH = 300;
export const MAX_EMAIL_LENGTH = 100;
export const MAX_LINK_LENGTH = 64000;

export const REQUIRED_FIELD = 'This field is required';

export const SWITCH_MANAGER_EVENT_LISTENER_INIT = 'SWITCH_MANAGER_EVENT_LISTENER_INIT';
export const SWITCH_ON_MANAGER_EVENT_LISTENER = 'SWITCH_ON_MANAGER_EVENT_LISTENER';

export const TRACK_INFO_INIT = 'TRACK_INFO_INIT';
export const TRACK_INFO_TITLE = 'TRACK_INFO_TITLE';
export const TRACK_INFO_TYPE = 'TRACK_INFO_TYPE';
export const TRACK_INFO_DESCR = 'TRACK_INFO_DESCR';
export const TRACK_INFO_TAG = 'TRACK_INFO_TAG';
export const TRACK_INFO_WRITER = 'TRACK_INFO_WRITER';
export const TRACK_INFO_PUBLISHER = 'TRACK_INFO_PUBLISHER';
export const TRACK_INFO_SHOW_EXPANDPANEL = 'TRACK_INFO_SHOW_EXPANDPANEL';
// export const TRACK_INFO_SET_WRITERS_OR_PUBLISHERS = 'TRACK_INFO_SET_WRITERS_OR_PUBLISHERS';
export const TRACK_INFO_ADD_ITEM_FOR_WRITERS_OR_PUBLISHERS = 'TRACK_INFO_ADD_ITEM_FOR_WRITERS_OR_PUBLISHERS';
export const TRACK_INFO_DELETE_ITEM_FROM_WRITERS_OR_PUBLISHERS = 'TRACK_INFO_DELETE_ITEM_FROM_WRITERS_OR_PUBLISHERS';
export const SET_OPEN_EDI_NEW_TRACK_POPUP = 'SET_OPEN_EDI_NEW_TRACK_POPUP';

export const NEW_COMPANY_JOB_INIT = 'NEW_COMPANY_JOB_INIT';
export const NEW_COMPANY_JOB_BUDGET = 'NEW_COMPANY_JOB_BUDGET';
export const NEW_COMPANY_JOB_DESCRIPION = 'NEW_COMPANY_JOB_DESCRIPION';
export const NEW_COMPANY_JOB_TITLE = 'NEW_COMPANY_JOB_TITLE';

export const MUS_PLAYER_INIT = 'MUS_PLAYER_INIT';
export const MUS_PLAYER_TITLE = 'MUS_PLAYER_TITLE';
export const MUS_PLAYER_DESCR = 'MUS_PLAYER_DESCR';
export const MUS_PLAYER_NAME_IN_BASE = 'MUS_PLAYER_NAME_IN_BASE';
export const MUS_PLAYER_ID = 'MUS_PLAYER_ID';
export const MUS_PLAYER_URL = 'MUS_PLAYER_URL';
export const MUS_PLAYER_SET_PLAY = 'MUS_PLAYER_SET_PLAY';
export const MUS_PLAYER_SET_PAUSE = 'MUS_PLAYER_SET_PAUSE';
export const SET_PLAY_FROM_MUSIC_SUBMISSIONS = 'SET_PLAY_FROM_MUSIC_SUBMISSIONS';
export const SET_PLAY_FROM_PLAYLIST = 'SET_PLAY_FROM_PLAYLIST';

export const AVATAR_INIT = 'AVATAR_INIT';
export const AVATAR_LOADED = 'AVATAR_LOADED';
export const AVATAR_ERROR = 'AVATAR_ERROR';
export const AVATAR_PENDING = 'AVATAR_PENDING';
export const AVATAR_SUCCESS = 'AVATAR_SUCCESS';

export const NEW_JOB_AVATAR_INIT = 'NEW_JOB_AVATAR_INIT';
export const NEW_JOB_AVATAR_SUCCESS = 'NEW_JOB_AVATAR_SUCCESS';
export const NEW_JOB_AVATAR_PENDING = 'NEW_JOB_AVATAR_PENDING';
export const NEW_JOB_AVATAR_ERROR = 'NEW_JOB_AVATAR_ERROR';
export const NEW_JOB_AVATAR_FILE_ID = 'NEW_JOB_AVATAR_FILE_ID';
export const NEW_JOB_AVATAR_SUCCESS_LOADED = 'NEW_JOB_AVATAR_SUCCESS_LOADED';

export const TEMP_COMPANY_JOBS_INIT = 'TEMP_COMPANY_JOBS_INIT';
export const TEMP_COMPANY_JOBS_EDIT_ITEM = 'TEMP_COMPANY_JOBS_EDIT_ITEM';

export const ADMIN_INIT = 'ADMIN_INIT';
export const ADMIN_SUCCESS = 'ADMIN_SUCCESS';
export const ADMIN_SUCCESS_NEXT = 'ADMIN_SUCCESS_NEXT';
export const ADMIN_PENDING = 'ADMIN_PENDING';
export const ADMIN_ERROR = 'ADMIN_ERROR';
export const ADMIN_CURRENT_USER = 'ADMIN_CURRENT_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';
export const ADMIN_CHANGE_USER = 'ADMIN_CHANGE_USER';

export const SITE_EVENTS_INIT = 'SITE_EVENTS_INIT';
export const SITE_EVENT_UPLOAD = 'SITE_EVENT_UPLOAD';
export const SITE_EVENT_LISTENED = 'SITE_EVENT_LISTENED';
export const SITE_EVENT_LOADING = 'SITE_EVENT_LOADING';
export const SITE_EVENT_ERROR = 'SITE_EVENT_ERROR';
export const SITE_EVENT_LISTENED_FOR_STATISTIC = 'SITE_EVENT_LISTENED_FOR_STATISTIC';
export const SITE_EVENT_SEND_SUCCESS = 'SITE_EVENT_SEND_SUCCESS';

export const COMPANIES_INIT = 'COMPANIES_INIT';
export const COMPANIES_LOADED = 'COMPANIES_LOADED';
export const ALL_COMPANIES_LOADED = 'ALL_COMPANIES_LOADED';
export const COMPANIES_ADD_NEW = 'COMPANIES_ADD_NEW';
export const COMPANIES_ERROR = 'COMPANIES_ERROR';
export const COMPANIES_PENDING = 'COMPANIES_PENDING';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_CURRENT = 'COMPANIES_CURRENT';
export const COMPANIES_DELETE_MEMBER = 'COMPANIES_DELETE_MEMBER';

export const INVITE_INIT = 'INVITE_INIT';
export const INVITE_CREATED_LOADED = 'INVITE_CREATED_LOADED';
export const INVITE_RECEIVED_LOADED = 'INVITE_RECEIVED_LOADED';
export const INVITE_CREATED_ADD_NEW = 'INVITE_CREATED_ADD_NEW';
export const INVITE_RECEIVED_ADD_NEW = 'INVITE_RECEIVED_ADD_NEW';
export const UPDATE_INVITE_STATUS = 'UPDATE_INVITE_STATUS';
export const INVITE_ERROR = 'INVITE_ERROR';
export const INVITE_PENDING = 'INVITE_PENDING';
export const INVITE_SUCCESS = 'INVITE_SUCCESS';
export const DELETE_RECEIVED_INVITE = 'DELETE_RECEIVED_INVITE';
export const DELETE_CREATED_INVITE = 'DELETE_CREATED_INVITE';

export const SHARED_PLAYLIST_INIT = 'SHARED_PLAYLIST_INIT';
export const SHARED_PLAYLIST_SUCCESS = 'SHARED_PLAYLIST_SUCCESS';
export const SHARED_PLAYLIST_RECEIVE = 'SHARED_PLAYLIST_RECEIVE';
export const SHARED_PLAYLIST_ERROR = 'SHARED_PLAYLIST_ERROR';
export const SHARED_PLAYLIST_ID = 'SHARED_PLAYLIST_ID';
export const FILTER_SHARED_PLAYLIST_BY_ID = 'FILTER_SHARED_PLAYLIST_BY_ID';

export const REG_FROM_INVITE_INIT = 'REG_FROM_INVITE_INIT';
export const REG_FROM_INVITE_SUCCESS = 'REG_FROM_INVITE_SUCCESS';
export const REG_FROM_INVITE_PENDING = 'REG_FROM_INVITE_PENDING';
export const REG_FROM_INVITE_ERROR = 'REG_FROM_INVITE_ERROR';
export const REG_FROM_INVITE_SUCCESS_VALUE = 'REG_FROM_INVITE_SUCCESS_VALUE';

export const SHARED_PLAYLIST_TRACKS_INIT = 'SHARED_PLAYLIST_TRACKS_INIT';
export const SHARED_PLAYLIST_TRACKS_SUCCESS = 'SHARED_PLAYLIST_TRACKS_SUCCESS';
export const SHARED_PLAYLIST_TRACKS_RECEIVE = 'SHARED_PLAYLIST_TRACKS_RECEIVE';
export const SHARED_PLAYLIST_TRACKS_ERROR = 'SHARED_PLAYLIST_TRACKS_ERROR';
export const SHARED_PLAYLIST_TRACKS_ID = 'SHARED_PLAYLIST_TRACKS_ID';
export const SHARED_PLAYLIST_DOWNLOAD_ZIP = 'SHARED_PLAYLIST_DOWNLOAD_ZIP';
export const SHARED_PLAYLIST_DOWNLOAD_ZIP_ERROR = 'SHARED_PLAYLIST_DOWNLOAD_ZIP_ERROR';
export const SHARED_PLAYLIST_DOWNLOAD_ZIP_LOADED = 'SHARED_PLAYLIST_DOWNLOAD_ZIP_LOADED';
export const SHARED_PLAYLIST_TRACKS_PENDING = 'SHARED_PLAYLIST_TRACKS_PENDING';

export const SH_PLAYLIST_INFO_INIT = 'SH_PLAYLIST_INFO_INIT';
export const SH_PLAYLIST_INFO_SUCCESS = 'SH_PLAYLIST_INFO_SUCCESS';
export const SH_PLAYLIST_INFO_PENDING = 'SH_PLAYLIST_INFO_PENDING';
export const SH_PLAYLIST_INFO_RECEIVE = 'SH_PLAYLIST_INFO_RECEIVE';
export const SH_PLAYLIST_INFO_ERROR = 'SH_PLAYLIST_INFO_ERROR';
export const SH_PLAYLIST_BG = 'SH_PLAYLIST_BG';

export const SH_PLAYLIST_LINKS_INIT = 'SH_PLAYLIST_LINKS_INIT';
export const SH_PLAYLIST_LINKS_SUCCESS = 'SH_PLAYLIST_LINKS_SUCCESS';
export const SH_PLAYLIST_LINKS_PENDING = 'SH_PLAYLIST_LINKS_PENDING';
export const SH_PLAYLIST_LINKS_RECEIVE = 'SH_PLAYLIST_LINKS_RECEIVE';
export const SH_PLAYLIST_LINKS_ERROR = 'SH_PLAYLIST_LINKS_ERROR';
export const SH_PLAYLIST_LINKS_ADD_NEW = 'SH_PLAYLIST_LINKS_ADD_NEW';

export const TRACK_INFORMATION_INIT = 'TRACK_INFORMATION_INIT';
export const TRACK_INFORMATION_FORMAT = 'TRACK_INFORMATION_FORMAT';
export const TRACK_INFORMATION_FILE_NAME = 'TRACK_INFORMATION_FILE_NAME';

export const SHARED_PLAYER_INIT = 'SHARED_PLAYER_INIT';
export const SHARED_PLAYER_SUCCESS = 'SHARED_PLAYER_SUCCESS';
export const SHARED_PLAYER_RECEIVE = 'SHARED_PLAYER_RECEIVE';
export const SHARED_PLAYER_ERROR = 'SHARED_PLAYER_ERROR';
export const SHARED_PLAYER_URL = 'SHARED_PLAYER_URL';
export const SHARED_PLAYER_PENDING = 'SHARED_PLAYER_PENDING';
export const SHARED_PLAYER_PROGRESS = 'SHARED_PLAYER_PROGRESS';
export const SHARED_PLAYER_PLAY_ALL = 'SHARED_PLAYER_PLAY_ALL';
export const SHARED_PLAYER_PLAY_TRACK = 'SHARED_PLAYER_PLAY_TRACK';

export const SUBS_LIST_INIT = 'SUBS_LIST_INIT';
export const SUBS_LIST_SUCCESS = 'SUBS_LIST_SUCCESS';
export const SUBS_LIST_PENDING = 'SUBS_LIST_PENDING';
export const SUBS_LIST_ERROR = 'SUBS_LIST_ERROR';
export const SUBS_LIST_ID = 'SUBS_LIST_ID';
export const SUBS_LIST_PERIOD = 'SUBS_LIST_PERIOD';

export const STRIPE_PUBLIC_KEY_DEV = 'pk_test_prjE7fX5qg1MKbFQn7HnKcxb00k4Qkzdr0';
export const STRIPE_PUBLIC_KEY_PROD = 'pk_live_UAKcPPx8jiDXa7tSn8eC2keA00FzfTea0Z';

export const PAYMENT_INIT = 'PAYMENT_INIT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_SET_TEXT = 'PAYMENT_SET_TEXT';

export const UNSUBSCRIBE_INIT = 'UNSUBSCRIBE_INIT';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_PENDING = 'UNSUBSCRIBE_PENDING';
export const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR';

export const UPDATE_APPROVE_INIT = 'UPDATE_APPROVE_INIT';
export const UPDATE_APPROVE_SUCCESS = 'UPDATE_APPROVE_SUCCESS';
export const UPDATE_APPROVE_PENDING = 'UPDATE_APPROVE_PENDING';
export const UPDATE_APPROVE_ERROR = 'UPDATE_APPROVE_ERROR';

export const CURR_SUBSCR_INIT = 'CURR_SUBSCR_INIT';
export const CURR_SUBSCR_SUCCESS = 'CURR_SUBSCR_SUCCESS';
export const CURR_SUBSCR_ERROR = 'CURR_SUBSCR_ERROR';
export const CURR_SUBSCR_PENDING = 'CURR_SUBSCR_PENDING';
export const CURR_SUBSCR_CLEAR_PRICE_ID = 'CURR_SUBSCR_CLEAR_PRICE_ID';

export const AFFILIATE_INIT = 'AFFILIATE_INIT';
export const AFFILIATE_SUCCESS = 'AFFILIATE_SUCCESS';
export const AFFILIATE_ERROR = 'AFFILIATE_ERROR';
export const AFFILIATE_PENDING = 'AFFILIATE_PENDING';
export const AFFILIATE_SEND_SUCCESS = 'AFFILIATE_SEND_SUCCESS';
export const AFFILIATE_SEND_ERROR = 'AFFILIATE_SEND_ERROR';
export const AFFILIATE_SEND_PENDING = 'AFFILIATE_SEND_PENDING';

export const LEAD_DYNO_PUBIC_KEY = '9af2e29b950e3d68f80f3cf7738be5466ffa58ec';

export const TEAM_INIT = 'TEAM_INIT';
export const TEAM_PENDING = 'TEAM_PENDING';
export const TEAM_ERROR = 'TEAM_ERROR';
export const TEAM_LIST = 'TEAM_LIST';
export const TEAM_DELETE = 'TEAM_DELETE';
export const TEAM_CREATE = 'TEAM_CREATE';

export const TEAM_INVITE_INIT = 'TEAM_INVITE_INIT';
export const TEAM_INVITE_PENDING = 'TEAM_INVITE_PENDING';
export const TEAM_INVITE_ERROR = 'TEAM_INVITE_ERROR';
export const TEAM_INVITE_LIST = 'TEAM_INVITE_LIST';
export const TEAM_INVITE_DELETE = 'TEAM_INVITE_DELETE';
export const TEAM_INVITE_CREATE = 'TEAM_INVITE_CREATE';
export const TEAM_INVITE_SENDED = 'TEAM_INVITE_SENDED';
export const TEAM_INVITE_UPDATE = 'TEAM_INVITE_UPDATE';

export const SUBS_LIMIT_INIT = 'SUBS_LIMIT_INIT';
export const SUBS_LIMIT_SUCCESS = 'SUBS_LIMIT_SUCCESS';
export const SUBS_LIMIT_PENDING = 'SUBS_LIMIT_PENDING';
export const SUBS_LIMIT_ERROR = 'SUBS_LIMIT_ERROR';

export const DOWNLOAD_FILE_INIT = 'DOWNLOAD_FILE_INIT';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';
export const DOWNLOAD_FILE_PENDING = 'DOWNLOAD_FILE_PENDING';

export const PM_INIT = 'PM_INIT';
export const PM_SUCCESS = 'PM_SUCCESS';
export const PM_ERROR = 'PM_ERROR';
export const PM_PENDING = 'PM_PENDING';

export const BILLING_INIT = 'BILLING_INIT';
export const BILLING_SUCCESS = 'BILLING_SUCCESS';
export const BILLING_ERROR = 'BILLING_ERROR';
export const BILLING_PENDING = 'BILLING_PENDING';
export const BILLING_DEFAULT = 'BILLING_DEFAULT';

export const ACC_INIT = 'ACC_INIT';
export const ACC_PENDING = 'ACC_PENDING';
export const ACC_ERROR = 'ACC_ERROR';
export const ACC_SUCCESS = 'ACC_SUCCESS';

export const STATISTICS_INIT = 'STATISTICS_INIT';
export const STATISTICS_SUCCESS = 'STATISTICS_SUCCESS';
export const STATISTICS_PENDING = 'STATISTICS_PENDING';
export const STATISTICS_ERROR = 'STATISTICS_ERROR';
